import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  NumberInput,
  SelectInput,
} from "react-admin";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const CategoryCreate = (props) => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Base Category">
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
        <MediaUploader
          attribute="background"
          label="Background"
          accept="images/*"
        />
        <TextInput source="name" fullWidth={true} />
        <TextInput source="sys_name" fullWidth={true} validate={[required()]} />
        <TextInput source="description" fullWidth={true} />
        <TextInput source="title" fullWidth={true} />
        <NumberInput min="1" source="order" fullWidth={true} />
        <ReferenceArrayInput
          label="Products"
          reference="products"
          source="products"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(product = {}) => {
              if (product == null) {
                return "";
              }
              const {
                region_code = "",
                art_nr = "",
                description_1 = "",
              } = product;
              return `${region_code} - ${art_nr} - ${description_1}`;
            }}
          />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Ranges"
          reference="ranges"
          source="ranges"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(range = {}) => {
              if (range == null) {
                return "";
              }
              const { region_code = "", name = "" } = range;
              return `${region_code} - ${name}`;
            }}
          />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label="FoodService">
        <MediaUploader
          attribute="fs_background"
          label="FS Background"
          accept="images/*"
        />
        <TextInput source="fs_description" fullWidth={true} />
        <TextInput source="fs_title" fullWidth={true} />
        <NumberInput min="1" source="fs_order" fullWidth={true} />
      </FormTab>
    </TabbedForm>
  </Create>
);
