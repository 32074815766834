import React, { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, NumberField, BooleanField } from 'react-admin'
import { PostPagination, PostFilter } from '../../components/QueryHandlers';
import { useDispatch } from 'react-redux';
import { supplierHelp } from '../../help';

export const SupplierList = props => {
    const dispatch = useDispatch();
    useEffect(() => {
    dispatch({ type: 'HELP_MESSAGE', payload: { content: '' }})
    dispatch({ type: 'HELP_IMAGE', payload: { content: supplierHelp }})
})
    return (
    <List {...props} pagination={<PostPagination />} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="region_code" />
            <TextField source="name" />
            <NumberField source="number" />
            <BooleanField source="bobbler" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
)
};