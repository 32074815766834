import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "brand";

export const BrandEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {isFieldAvailable("logo", props, RESOURCE_NAME) ? (
        <MediaUploader attribute="logo" label="Logo" accept="images/*" />
      ) : null}

      {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
        <SelectInput
          source="region_code"
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}

      {isFieldAvailable("name", props, RESOURCE_NAME) ? (
        <TextInput source="name" fullWidth={true} />
      ) : null}
      {isFieldAvailable("products", props, RESOURCE_NAME) ? (
        <ReferenceArrayInput
          label="Products"
          reference="products"
          source="products"
          filter={{ fetchMode: "reflist" }}
          fullWidth={true}
        >
          <AutocompleteArrayInput
            optionText={(record) => {
              if (record == null) {
                return "";
              }
              if (record && record.art_nr && record.description_1) {
                return `${record.region_code} - ${record.art_nr} - ${record.description_1}`;
              }

              return "";
            }}
          />
        </ReferenceArrayInput>
      ) : null}
    </SimpleForm>
  </Edit>
);
