import React, { useEffect } from "react";
import {
  DEV_ENV,
  DEV_ENV_HEADER,
  LOCAL_ENV,
  LOCAL_ENV_HEADER,
  STAGING_ENV,
  STAGING_ENV_HEADER,
  PROD_OLD_ENV,
  PROD_OLD_ENV_HEADER,
} from "../../utils/constants";

const EnvironmentHeader = () => {
  const env = process.env.REACT_APP_NODE_ENV;
  const headerText =
    env === LOCAL_ENV
      ? LOCAL_ENV_HEADER
      : env === DEV_ENV
      ? DEV_ENV_HEADER
      : env === STAGING_ENV
      ? STAGING_ENV_HEADER
      : env === PROD_OLD_ENV
      ? PROD_OLD_ENV_HEADER
      : null;

  useEffect(() => {
    if (env === STAGING_ENV) {
      document.title = STAGING_ENV_HEADER;
    }
  }, []);

  return headerText && <div className="env-header">{headerText}</div>;
};

export default EnvironmentHeader;
