import React from "react";
import {
  SelectInput,
  required,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import MediaUploader from "../../components/MediaUploader";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const RangeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <MediaUploader attribute="icon" label="Icon" accept="images/*" />
      <MediaUploader
        attribute="background"
        label="Background"
        accept="images/*"
      />
      <TextInput fullWidth={true} source="title" />
      <ReferenceArrayInput
        label="Categories"
        reference="categories"
        source="categories"
        filter={{ fetchMode: "reflist" }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          optionText={(categories = {}) => {
            if (categories == null) {
              return "";
            }
            const { region_code = "", sys_name = "" } = categories;
            return `${region_code} - ${sys_name}`;
          }}
        />
      </ReferenceArrayInput>

      <TextInput fullWidth={true} source="name" validate={[required()]} />
      <TextInput fullWidth={true} source="description" />
      <NumberInput min="1" source="order" fullWidth={true} />
      <ReferenceArrayInput
        fullWidth={true}
        label="Products"
        reference="products"
        source="products"
        filter={{ fetchMode: "reflist" }}
      >
        <AutocompleteArrayInput optionText="description_1" />
      </ReferenceArrayInput>
      <ColorInput source="color" fullWidth={true} />
    </SimpleForm>
  </Create>
);
