import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  required,
} from "react-admin";
import { makeRegionChoices } from "../../../utils/regionSelector";

const getProductOptionText = (record) => {
  if (record == null) {
    return "";
  }
  if (record !== undefined) {
    return `${record.region_code} - ${record.art_nr} - ${record.description_1}`;
  }

  return "";
};

export const FsMatchCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        source="region_code"
        choices={makeRegionChoices()}
        validate={[required()]}
        fullWidth
      />
      <TextInput source="header" fullWidth={true} />
      <TextInput source="product_txt" fullWidth={true} />
      <ReferenceArrayInput
        label="Main Products"
        reference="products"
        source="main_products"
        filter={{ fetchMode: "reflist", show_in_foodservice: true }}
        fullWidth={true}
      >
        <AutocompleteArrayInput optionText={getProductOptionText} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Matched Products"
        reference="products"
        source="matched_products"
        filter={{ fetchMode: "reflist", show_in_foodservice: true }}
        fullWidth={true}
      >
        <AutocompleteArrayInput optionText={getProductOptionText} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
