import React, { Fragment, useState, useEffect } from 'react';
import { useMutation, SimpleForm, FileInput, FileField, TextInput, Loading } from 'react-admin';
import { Button } from 'ra-ui-materialui';
import { useDispatch } from 'react-redux';

const DATA_URL = process.env.REACT_APP_DATA_URL;
const ST_PENDING = 'pending';
const ST_SUCCESS = 'success';
const AUTO_IMPORT = 'autoImport';
const MANUAL_IMPORT = 'manualImport';

export const Tools = (props) => {
  const [mutate] = useMutation();
  const [status, setStatus] = useState(null);
  const [stateIntvalId, setStateIntvalId] = useState(null);
  const [importMethod, setImportMethod] = useState(null);

  const save = (data) => {
    setStatus(ST_PENDING);
    return mutate({
      type: 'create',
      resource: 'navison-imports',
      payload: { data },
    });
  };
  const dispatch = useDispatch();
  const content = `<h1>Tools page</h1><br>You can set import process, and start it here`;

  // Checking the import process by polling. If response is "pending" the loading is visible!
  useEffect(() => {
    var intvalIdToClose = 0;
    if (!stateIntvalId) {
      const token = localStorage.getItem('token');
      const method = 'GET';
      const headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      });
      const url = `${DATA_URL}/navison-imports?_sort=id:ASC&_start=0&_limit=10&_q=${ST_PENDING}`;
      const intvalId = setInterval(() => {
        intvalIdToClose = intvalId;

        if (status === null || status === ST_PENDING) {
          fetch(url, { method, headers })
            .then((data) => data.json())
            .then(([data]) => {
              if (data && data.status === ST_PENDING) {
                setStatus(data.status);
                stateIntvalId(null);
              } else {
                setStatus(ST_SUCCESS);
                clearInterval(intvalId);
                setStateIntvalId(intvalIdToClose);
              }
            })
            .catch((error) => console.error('CATCH:', error));
        }
      }, 5000);
    } else if (status !== ST_PENDING) {
      clearInterval(intvalIdToClose);
      setStateIntvalId(intvalIdToClose);
    }
    dispatch({ type: 'HELP_MESSAGE', payload: { content } });
    dispatch({ type: 'HELP_IMAGE', payload: { content: '' } });

    return () => {
      clearInterval(intvalIdToClose);
      setStateIntvalId(null);
    };
  }, [status, content, dispatch, stateIntvalId]);

  const handleImportMethodChange = () => {
    if (importMethod === AUTO_IMPORT) {
      setImportMethod(MANUAL_IMPORT);
    } else {
      setImportMethod(AUTO_IMPORT);
    }
  };

  const handleStartAutoImport = () => {
    const token = localStorage.getItem('token');
    const method = 'GET';
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const url = `${DATA_URL}/navison-imports/ftp`;

    fetch(url, { method, headers }).then((data) => {
      if (data) {
        setStatus(ST_PENDING);
      }
    });
  };

  return (
    <Fragment>
      {status === null && <Loading />}
      {status === ST_PENDING && (
        <Loading
          loadingPrimary='Importing is in process, it takes several minutes!'
          loadingSecondary='You can visit other part of admin and come back as well anytime.'
        />
      )}
      {status === ST_SUCCESS && (
        <Fragment>
          <Button
            label='CLICK TO CHANGE IMPORT METHOD'
            variant='contained'
            color='primary'
            onClick={() => handleImportMethodChange()}
          />
          <br />
          <br />
          <br />
          <br />
        </Fragment>
      )}
      {importMethod === MANUAL_IMPORT && status === ST_SUCCESS && (
        <Fragment>
          <h2>This way you need to upload the CSV manually!</h2>
          <p>
            The system will accept only CSV by name: Customer.csv, Vendor.csv, Item.csv,
            Foodservice_product.csv
          </p>
          <SimpleForm resource='navison-imports' save={save}>
            <TextInput source='name' label='Description' fullWidth />
            <FileInput source='upload' label='DROP CSV HERE' accept='text/csv'>
              <FileField source='upload' title='title' />
            </FileInput>
          </SimpleForm>
        </Fragment>
      )}
      {importMethod === AUTO_IMPORT && status === ST_SUCCESS && (
        <Fragment>
          <h2>This way the whole import process will be done by automatically.</h2>
          <p>Downloading all the CSV from FTP and import with images from GS1.</p>
          <p>
            The system every 4 am make an import by default. You can check anytime the last import
            process results in the GateKeeper menu.
          </p>
          <Button
            label='Click to button to START IMPORT'
            variant='contained'
            color='primary'
            onClick={() => handleStartAutoImport()}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
