import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  SelectInput,
  required,
} from "react-admin";
import isFieldAvailable from "../../../utils/isFieldAvailable";
import { makeRegionChoices } from "../../../utils/regionSelector";
const RESOURCE_NAME = "customer";

export const CustomerEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        {isFieldAvailable("region_code", props, RESOURCE_NAME) ? (
          <SelectInput
            source="region_code"
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
        ) : null}
        {isFieldAvailable("name", props, RESOURCE_NAME) ? (
          <TextInput fullWidth={true} source="name" />
        ) : null}
        {isFieldAvailable("products", props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            label="Products"
            reference="products"
            source="products"
            filter={{ fetchMode: "reflist" }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(product = {}) => {
                if (product == null) {
                  return "";
                }
                const {
                  region_code = "",
                  art_nr = "",
                  description_1 = "",
                } = product;
                return `${region_code} - ${art_nr} - ${description_1}`;
              }}
            />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable("countries", props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            fullWidth={true}
            label="Countries"
            reference="countries"
            source="countries"
            filter={{ fetchMode: "reflist" }}
          >
            <AutocompleteArrayInput
              optionText={(country = {}) => {
                if (country == null) {
                  return "";
                }
                const { region_code = "", name = "" } = country;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceArrayInput>
        ) : null}
        {isFieldAvailable("consumer_labels", props, RESOURCE_NAME) ? (
          <ReferenceArrayInput
            resource="consumer_labels"
            source="consumer_labels"
            reference="consumer-labels"
            filter={{ fetchMode: "reflist" }}
            perPage={Number.MAX_SAFE_INTEGER}
            allowEmpty
            label="CONSUMER LABELING ON PACKAGING"
            fullWidth={true}
          >
            <CheckboxGroupInput
              optionText={(label = {}) => {
                if (label == null) {
                  return "";
                }
                const { region_code = "", name = "" } = label;
                return `${region_code} - ${name}`;
              }}
              optionValue="id"
            />
          </ReferenceArrayInput>
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
