import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  AutocompleteArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  AutocompleteInput,
  SelectInput,
  BooleanInput,
  required,
} from "react-admin";
import { makeRegionChoices } from "../../../utils/regionSelector";

export const ProductCreate = (props) => {
  return (
    <Create {...props} undoable={false}>
      <TabbedForm>
        <FormTab label="Base Product">
          <TextInput label="ART.NO." source="art_nr" fullWidth={true} />
          <TextInput
            label="PRODUCT DESCRIPTION 1"
            source="description_1"
            fullWidth={true}
          />
          <TextInput
            label="PRODUCT DESCRIPTION 2"
            source="description_2"
            fullWidth={true}
          />
          <TextInput label="PRODUCT TXT" source="text" fullWidth={true} />
          <BooleanInput
            source="show_in_foodservice"
            label="SHOW IN FoodService"
          />
          <ReferenceArrayInput
            resource="customers"
            source="customers"
            reference="customers"
            filter={{ fetchMode: "reflist" }}
            perPage={Number.MAX_SAFE_INTEGER}
            allowEmpty
            label="ONLY SHOW PRODUCT"
            fullWidth={true}
          >
            <CheckboxGroupInput
              optionText={(customer = {}) => {
                if (customer == null) {
                  return "";
                }
                const { region_code = "", name = "" } = customer;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceArrayInput>
          <TextInput source="netto_weight" fullWidth={true} />
          <TextInput source="number_package_pr" fullWidth={true} />
          <TextInput source="number_package" fullWidth={true} />
          <TextInput source="layers_pallet" fullWidth={true} />
          <TextInput source="days_of_durability" fullWidth={true} />
          <TextInput source="min_packaging_print" fullWidth={true} />
          <TextInput source="min_batch" fullWidth={true} />
          <TextInput source="ean_number" fullWidth={true} />
          <SelectInput
            source="region_code"
            choices={makeRegionChoices()}
            validate={[required()]}
            fullWidth
          />
          <ReferenceInput
            resource="supplier"
            source="supplier"
            reference="suppliers"
            filter={{ fetchMode: "reflist" }}
            allowEmpty
            label="CHOOSE SUPPLIER"
            fullWidth={true}
          >
            <AutocompleteInput
              optionText={(supplier = {}) => {
                if (supplier == null) {
                  return "";
                }
                const { region_code = "", name = "" } = supplier;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="brand"
            reference="brands"
            filter={{ fetchMode: "reflist" }}
            allowEmpty
            label="CHOOSE BRAND"
            fullWidth={true}
          >
            <AutocompleteInput
              optionText={(brand = {}) => {
                if (brand == null) {
                  return "";
                }
                const { region_code = "", name = "" } = brand;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="range"
            reference="ranges"
            filter={{ fetchMode: "reflist" }}
            allowEmpty
            label="CHOOSE RANGE"
            fullWidth={true}
          >
            <AutocompleteInput
              optionText={(range = {}) => {
                if (range == null) {
                  return "";
                }
                const { region_code = "", name = "" } = range;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Categories"
            reference="categories"
            source="categories"
            filter={{ fetchMode: "reflist" }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(categories = {}) => {
                if (categories == null) {
                  return "";
                }
                const { region_code = "", sys_name = "" } = categories;
                return `${region_code} - ${sys_name}`;
              }}
            />
          </ReferenceArrayInput>
          <ReferenceInput
            resource="series_item"
            source="series_item"
            reference="series-items"
            filter={{ fetchMode: "reflist" }}
            allowEmpty={false}
            label="CHOOSE SERIES ITEM"
            fullWidth={true}
          >
            <AutocompleteInput
              optionText={(series = {}) => {
                if (series == null) {
                  return "";
                }
                const { region_code = "", header = "" } = series;
                return `${region_code} - ${header}`;
              }}
            />
          </ReferenceInput>
          <ReferenceArrayInput
            resource="languages"
            source="languages"
            reference="languages"
            filter={{ fetchMode: "reflist" }}
            perPage={Number.MAX_SAFE_INTEGER}
            allowEmpty
            label="LANGUAGES ON PACKAGING"
            fullWidth={true}
          >
            <CheckboxGroupInput
              optionText={(language = {}) => {
                if (language == null) {
                  return "";
                }
                const { region_code = "", name = "" } = language;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            resource="consumer_labels"
            source="consumer_labels"
            reference="consumer-labels"
            filter={{ fetchMode: "reflist" }}
            perPage={Number.MAX_SAFE_INTEGER}
            allowEmpty
            label="CONSUMER LABELING ON PACKAGING"
            fullWidth={true}
          >
            <CheckboxGroupInput
              optionText={(label = {}) => {
                if (label == null) {
                  return "";
                }
                const { region_code = "", name = "" } = label;
                return `${region_code} - ${name}`;
              }}
              optionValue="id"
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Retailers"
            reference="retailers"
            source="retailers"
            filter={{ fetchMode: "reflist" }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(retailer = {}) => {
                if (retailer == null) {
                  return "";
                }
                const { region_code = "", name = "" } = retailer;
                return `${region_code} - ${name}`;
              }}
            />
          </ReferenceArrayInput>
        </FormTab>

        <FormTab label="FoodService">
          <TextInput
            label="FS PRODUCT DESCRIPTION 1"
            source="fs_description_1"
            fullWidth={true}
          />
          <TextInput source="ingredients" fullWidth={true} />
          <TextInput
            source="nutritional_values.energy.kj"
            label="Energy (kJ)"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.energy.kcal"
            label="Energy (kCal)"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.fat.total"
            label="Fat"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.fat.saturated"
            label="Fat - Saturated"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.carbohydrates.total"
            label="Carbohydrates"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.carbohydrates.sugar"
            label="Carbohydrates - Sugar"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.fibers"
            label="Fibers"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.proteins"
            label="Proteins"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.salt"
            label="Salt"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.dry_matter"
            label="Dry Matter (%)"
            fullWidth={true}
          />
          <TextInput
            source="nutritional_values.milk_fat"
            label="Milk fat"
            fullWidth={true}
          />
          <BooleanInput
            source="nutritional_values.homogenized"
            label="Homogenized"
          />
          <TextInput source="cooking" fullWidth={true} />
          <TextInput source="storytelling" fullWidth={true} />
          <ReferenceArrayInput
            label="FS Matches (Main)"
            reference="fs-matches"
            source="fs_matches_main"
            filter={{ fetchMode: "reflist" }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(fs_match = {}) => {
                if (fs_match == null) {
                  return "";
                }
                const { region_code = "", header = "" } = fs_match;
                return `${region_code} - ${header}`;
              }}
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="FS Matches (Matched)"
            reference="fs-matches"
            source="fs_matches_matched"
            filter={{ fetchMode: "reflist" }}
            fullWidth={true}
          >
            <AutocompleteArrayInput
              optionText={(fs_match = {}) => {
                if (fs_match == null) {
                  return "";
                }
                const { region_code = "", header = "" } = fs_match;
                return `${region_code} - ${header}`;
              }}
            />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
