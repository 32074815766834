import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  DeleteButton,
  usePermissions,
  useDataProvider,
  SelectInput,
} from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
const RESOURCE_NAME = 'user';

const UserEditToolbar = (props) => {
  const { permissions } = usePermissions();

  return (
    <Toolbar {...props}>
      <SaveButton />
      {permissions && permissions.role === 'Admin' && <DeleteButton />}
    </Toolbar>
  );
};

export const UserEdit = (props) => {
  const [roles, setRoles] = React.useState([]);
  const [region_codes, setRegionCodes] = React.useState([]);
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .getList('user-roles', {
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
      })
      .then(({ data: { roles: roleResults, region_code } }) => {
        setRoles(
          roleResults.filter(({ id, name }) => {
            if (name === 'Admin' || name === 'Presenter' || name === 'User') {
              return { id, name };
            }
          })
        );
        setRegionCodes([
          { id: 'DK', name: 'DK' },
          { id: 'SE', name: 'SE' },
          { id: 'NO', name: 'NO' },
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  return (
    <Edit {...props} undoable={false} toolbar={<UserEditToolbar />}>
      <SimpleForm>
        {isFieldAvailable('username', props, RESOURCE_NAME) && <TextInput source='username' />}
        {isFieldAvailable('email', props, RESOURCE_NAME) && <TextInput source='email' />}
        {isFieldAvailable('password', props, RESOURCE_NAME) && <TextInput source='password' />}
        {isFieldAvailable('role', props, RESOURCE_NAME) && (
          <SelectInput source='role' choices={roles} />
        )}
        {isFieldAvailable('region_code', props, RESOURCE_NAME) && (
          <SelectInput source='region_code' choices={region_codes} />
        )}
      </SimpleForm>
    </Edit>
  );
};
