import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, required } from 'react-admin';
import isFieldAvailable from '../../../utils/isFieldAvailable';
import MediaUploader from '../../components/MediaUploader';
import { makeRegionChoices } from '../../../utils/regionSelector';
const RESOURCE_NAME = 'csr';

export const CsrEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      {isFieldAvailable('region_code', props, RESOURCE_NAME) ? (
        <SelectInput
          source='region_code'
          choices={makeRegionChoices()}
          validate={[required()]}
          fullWidth
        />
      ) : null}
      {isFieldAvailable('pdf', props, RESOURCE_NAME) && (
        <MediaUploader attribute='pdf' withVisibilityFeature label='PDF documents' />
      )}
      {isFieldAvailable('pictures', props, RESOURCE_NAME) && (
        <MediaUploader attribute='pictures' multiple withVisibilityFeature label='Pictures' />
      )}
      {isFieldAvailable('title', props, RESOURCE_NAME) && <TextInput source='title' fullWidth />}
      {isFieldAvailable('description', props, RESOURCE_NAME) && (
        <TextInput source='description' fullWidth />
      )}
      {isFieldAvailable('year', props, RESOURCE_NAME) && <TextInput source='year' fullWidth />}
    </SimpleForm>
  </Edit>
);
